const en = {
    language: 'EN',
    fLink: 'Friendly Link',
    aboutTitle: 'China AGI Brand Gallery',
    home: 'Home',
    academicField: 'Academic Field',
    introduction: 'Introduction',
    relevantImage: 'Relevant brand image',
    brandNews: 'Brand News',
    htmlTitle: 'China AGI Brand Gallery',
    brandimage: "Brand Image",
    packing: 'Packaging System',
    cultural: 'Cultural and Creative Products',
    brandVideo: 'Brand Image Video',
    relevantText: 'Related text introduction',
    rankingTitle: 'China AGI Brand Ranking',
    category: 'category',
    fullList:'read more',
    enrollUp:'More'
}
export default en