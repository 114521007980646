import http from "../http/http"

// 获取栏目（顶部nav）
export const getNavBar = (params) => {
    return http({
        url: "/websiteBrand/queryAllAndSon",
        method: "post",
        data: params
    })
}

// 首页banner
export const getBanner = (params) => {
    return http({
        url: "/websiteBrand/queryBanner",
        method: "post",
        data: params
    })
}

// 首页品牌馆
export const homeBrand = (params) => {
    return http({
        url: "/websiteBrand/queryBrandIndex",
        method: "post",
        data: params
    })
}

// 首页形象仓库
export const homeWarehouse = (params) => {
    return http({
        url: "/websiteBrand/queryImageWarehouseIndex",
        method: "post",
        data: params
    })
}

// 首页地图数据
export const mapData = (params) => {
    return http({
        url: "/websiteBrand/queryLandmarkToArea",
        method: "post",
        data: params
    })
}


// 首页公告
export const homeNotice = (params) => {
    return http({
        url: "/websiteBrand/queryForHome",
        method: "post",
        data: params
    })
}

// 首页介绍
export const homeIntroduce = (params) => {
    return http({
        url: "/websiteBrand/queryIndexInfo",
        method: "post",
        data: params
    })
}

// 品类列表
export const homeDictList = (params) => {
    return http({
        url: "/sysDataDict/queryAllForOpen",
        method: "post",
        data: params
    })
}

// 首页榜单
export const homeRankingList = (params) => {
    return http({
        url: "/websiteBrand/queryIndexReputationList",
        method: "post",
        data: params
    })
}