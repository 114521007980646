const ch = {
    language: 'CH',
    fLink: '友情链接',
    aboutTitle: '中国农产品地理标志品牌形象馆',
    home: '首页',
    academicField: '学术场域',
    introduction: '简介',
    relevantImage: '查看相关品牌形象',
    brandNews: '品牌动态',
    htmlTitle: '中国农产品地理标志品牌形象馆',
    brandimage: "品牌形象",
    packing: '品牌包装',
    cultural: '品牌文创',
    brandVideo: '品牌形象片',
    relevantText: '查看相关文字介绍',
    rankingTitle: '中国地理标志农产品品牌声誉榜单',
    category:'品类',
    fullList:'查看完整榜单',
    enrollUp:'点击进入报名'
}
export default ch