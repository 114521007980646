import axios from 'axios'
import qs from "qs"

const instance = axios.create({
    baseURL: process.env.VUE_APP_URL,
    // baseURL: 'http://cloudservice.mzszxc.com:8086/yszsg',
    // 超时时间 单位是ms
    timeout: 8 * 1000
})
// console.log(process.env)
// const tenantId = '1422440750682996736'
// const siteId = '1422454440404713472'
// const siteId = '1422454502115508224'
// 添加请求拦截器
instance.interceptors.request.use((config) => {
    // store.isLoading = true
    // 在发送请求之前做些什么
    // config.headers.channelToken = Cookies.get('channelToken')
    let siteId = localStorage.getItem('siteId') ? localStorage.getItem('siteId') : '1422454440404713472'
    config.data = { ...config.data, ...{ siteId: siteId, tenantId: '1422440750682996736' } }
    config.data = qs.stringify(config.data)
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // store.isLoading = fasle
    // 对响应数据做点什么  
    // if (response.status == "200") {
    //     if (response.data.code == "1001") {
    //         const loginUrl = Cookies.get("sysUrl")
    //         Cookies.remove("userName", { domain: "abc.com" })
    //         Cookies.remove("token", { domain: "abc.com" })
    //         localStorage.clear()
    //         window.location.href = loginUrl
    //     }
    // }
    return response.data
}, function (error) {
    return Promise.reject(error)
})

export default instance