
import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "./en.js"  //自定义英文文件
import ch from "./ch.js"  //自定义中文文件
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: localStorage.getItem("locale") || "ch",
    messages: { ch, en }
})
export default i18n