import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    // component: () => import('@/layout/layout'),
    redirect: "/openAnimation",
    // children: [
    //   {
    //     path: "/home",
    //     name: "home",
    //     component: () => import('../views/home/home.vue'),
    //     meta: { title: '首页', titleE: 'Home', footerShow: true, breadcrumb: true },
    //     beforeEnter: (to, from, next) => {
    //       if (!sessionStorage.getItem('played')) {
    //         next({ path: '/openAnimation' })
    //       }
    //       next()
    //     }
    //   }
    // ]
  },
  {
    path: '/home',
    component: () => import('@/layout/layout'),
    redirect: "/home/index",
    children: [
      {
        path: "index",
        name: "home",
        component: () => import('../views/home/home.vue'),
        meta: { title: '首页', titleE: 'Home', footerShow: true, breadcrumb: true }
      }
    ]
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/layout/layout'),
    redirect: "/brand/brandList",
    meta: { title: '品牌基础', titleE: 'Brand Competences' },
    children: [
      {
        path: "brandList",
        name: "brandList",
        component: () => import('../views/brand/brandList.vue'),
      },
      {
        path: "brandItem",
        name: "brandItem",
        component: () => import('../views/brand/brandItem.vue'),
        meta: { footerShow: true, title: '品牌详情', titleE: 'BrandItem' }
      }
    ]
  },
  {
    path: '/iconStoreHouse',
    name: 'iconStoreHouse',
    component: () => import('@/layout/layout'),
    redirect: "/iconStoreHouse/iconList",
    meta: { title: '形象仓库', titleE: 'Image Gallery' },
    children: [
      {
        path: "iconList",
        name: "iconList",
        component: () => import('../views/iconStoreHouse/iconList.vue'),
      },
      {
        path: "iconItem",
        name: "iconItem",
        component: () => import('../views/iconStoreHouse/iconItem.vue'),
        meta: { footerShow: true, title: '形象详情', titleE: 'IconItem' }
      }
    ]
  },
  {
    path: '/academicField',
    name: 'academicField',
    component: () => import('@/layout/layout'),
    redirect: "/academicField/articleList",
    meta: { title: '学术场域', titleE: 'Academic Field' },
    children: [
      {
        path: "articleList",
        name: "articleList",
        component: () => import('../views/academicField/articleList.vue'),
        meta: { footerShow: true, breadcrumb: true }
      },
      {
        path: "articleDetails",
        name: "articleDetails",
        component: () => import('../views/academicField/articleDetails.vue'),
        meta: { footerShow: true, breadcrumb: true }
      }
    ]
  },
  // {
  //   path: '/helpCenter',
  //   name: 'helpCenter',
  //   component: () => import('@/layout/layout'),
  //   redirect: "/helpCenter/help",
  //   meta: { title: '帮助中心', titleE: 'Help' },
  //   children: [
  //     {
  //       path: "help",
  //       name: "help",
  //       component: () => import('../views/helpCenter/help.vue')
  //     }
  //   ]
  // },
  {
    path: '/brandDynamic',
    name: 'brandDynamic',
    component: () => import('@/layout/layout'),
    redirect: "/brandDynamic/dynamicList",
    meta: { title: '品牌动态', titleE: 'Brand News' },
    children: [
      {
        path: "dynamicList",
        name: "dynamicList",
        component: () => import('../views/brandDynamic/dynamicList.vue'),
        meta: { footerShow: true, breadcrumb: true }
      },
      {
        path: "dynamicItem",
        name: "dynamicItem",
        component: () => import('../views/brandDynamic/dynamicItem.vue'),
        meta: { footerShow: true, breadcrumb: true }
      },
      {
        path: "enroll",
        name: "enroll",
        component: () => import('../views/brandDynamic/enroll.vue'),
        meta: { footerShow: true, breadcrumb: true }
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/layout/layout'),
    redirect: "/about/aboutUs",
    meta: { title: '关于我们', titleE: 'About Us' },
    children: [
      {
        path: "aboutUs",
        name: "aboutUs",
        component: () => import('../views/about/aboutUs.vue')
      }
    ]
  },
  {
    path: '/openAnimation',
    name: 'openAnimation',
    component: () => import('../views/openAnimation/openAnimation.vue'),
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

// 跳转不存在的页面时，回到上一页或首页
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next('/')
    next()
  } else {
    next()
  }
})
export default router
