import Vue from 'vue'
import Vuex from 'vuex'
import { getNavBar } from '@/api/home'
Vue.use(Vuex)
const pathList = [
  {
    columnCode: '0101',
    path: '/home'
  },
  {
    columnCode: '0102',
    path: '/brand'
  },
  {
    columnCode: '0103',
    path: '/iconStoreHouse'
  },
  {
    columnCode: '0104',
    path: '/academicField?columnCode=010402'
  },
  {
    columnCode: '0105',
    path: '/brandDynamic?columnCode=0105'
  },
  {
    columnCode: '0106',
    path: '/about'
  }
]
const fixedLink = [
  {
    websiteName: '全国农产品地理标志查询系统',
    englishName: 'AGI Search System',
    websiteUrl: 'http://www.anluyun.com/'
  },
  {
    websiteName: '地理标志产品查询',
    englishName: 'GI Search System',
    websiteUrl: 'https://dlbzsl.hizhuanli.cn:8888/Product/Search'
  },
  {
    websiteName: '中国绿色食品发展中心',
    englishName: 'China Green Food Development Center',
    websiteUrl: 'http://www.greenfood.org.cn'
  },
  {
    websiteName: '国家知识产权局',
    englishName: 'China National Intellectual Property Administration',
    websiteUrl: 'https://www.cnipa.gov.cn'
  }
]
export default new Vuex.Store({
  state: {
    navBarList: [],
    LinksList: [],
    breadcrumbList: [{ path: "/home", meta: { title: "首页", titleE: "Home" } }],
    musicState: true,
    language: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")) : '' || true,
  },
  mutations: {
    addNavBar(state, payload) {
      state.navBarList = payload
    },
    // 根据页面添加友情链接
    addLinks(state, payload) {
      let arr = [...fixedLink, ...payload]
      state.LinksList = arr
    },
    // 默认展示的友情链接
    defaultLink(state) {
      state.LinksList = fixedLink
    },
    // 暂停背景音乐
    pauseMusic(state, payload) {
      state.musicState = false
    },
    // 播放背景音乐
    playMusic(state, payload) {
      state.musicState = true
    },
    // 增加面包屑
    addBreadcrumb(state, payload) {
      state.breadcrumbList = payload
    },
    // 切换语言
    changeLanguage(state, payload) {
      localStorage.setItem("language", payload)
      state.language = payload
    }
  },
  actions: {
    // 获取顶部nav
    getNavBarList(context) {
      return new Promise((resolve, reject) => {
        getNavBar().then(res => {
          if (res.code === 10000) {
            pathList.forEach(item => {
              res.data.forEach(item2 => {
                if (item.columnCode === item2.columnCode) {
                  item2.path = item.path
                }
              })
            })
            context.commit('addNavBar', res.data)
            resolve()
          } else {
            reject()
          }
        })
      })
    }
  },
  modules: {
  }
})
