<template>
  <div id="app">
    <keep-alive include='home,brandList,iconList,articleList,dynamicList,aboutUs'>
      <router-view v-if="isRouterAlive" />
    </keep-alive>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapState({
      breadcrumbList: (state) => {
        return state.breadcrumbList
      }
    })
  },
  watch: {
    $route(to, from) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      // 设置面包屑数据
      let matched = this.$route.matched
      let arr = []
      matched = [
        { path: '/home', meta: { title: '首页', titleE: 'Home' } }
      ].concat(matched)
      matched.forEach((item) => {
        if (item.meta.title) {
          arr.push(item)
        }
      })
      this.$store.commit('addBreadcrumb', arr)
      this.$store.commit('defaultLink')
    }
  },
  data() {
    return {
      includeArr: ['brandList'],
      isRouterAlive: true //控制视图是否显示的变量
    }
  },
  created() {
    // let siteId = localStorage.getItem('siteId')
    // if (!siteId) {
    //   localStorage.setItem('siteId', '1422454440404713472')
    // }
    if (this.isIE()) {
      this.isRouterAlive = false
      alert(
        '当前浏览器版本过低,请使用浏览器的高速模式或使用Chrome浏览器已获得完整体验'
      )
    }
  },
  mounted() {
    const mq = window.matchMedia('(max-width: 960px)')
    if (mq.matches) {
      window.location.replace(this.getMobilePage())
    }

    window.onresize = () => {
      let clientWidth = document.body.clientWidth
      if (clientWidth <= 960) {
        // window.location.replace('http://www.cagibrand.com/H5/#/')
        window.location.replace(this.getMobilePage())
      }
    }
  },
  methods: {
    // 获取url的所有参数
    getUrlParam() {
      let url = window.location.href
      let arrObj = url.split('?')
      let params = Object.create(null)
      if (arrObj.length > 1) {
        arrObj = arrObj[1].split('&')
        arrObj.forEach((item) => {
          item = item.split('=')
          params[item[0]] = item[1]
        })
      }
      return params
    },
    // 获取移动端对应的地址
    getMobilePage() {
      let pageArr = [
        '/brand/brandList',
        '/brand/brandItem',
        '/iconStoreHouse/iconList',
        '/iconStoreHouse/iconItem',
        '/academicField/articleList',
        '/academicField/articleDetails',
        '/brandDynamic/dynamicList',
        '/brandDynamic/dynamicItem'
      ]
      let href = window.location.href
      let options = window.location.href.split('?')[1]
      let url = 'http://www.cagibrand.com/H5/#/'
      pageArr.forEach((item) => {
        if (href.indexOf(item) != -1) {
          url = `http://www.cagibrand.com/H5/#/pages${item}?${options}`
        }
      })
      return url
    },
    // 重新加载页面
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 判断是否是IE
    isIE() {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

li {
  list-style: none;
}

img {
  border: 0;
  outline: 0;
  vertical-align: middle;
  /* max-width: 100%;
  max-height: 100%; */
}
html,
body,
#app {
  height: 100%;
}
@font-face {
  font-family: siyuan;
  src: url('./static/css/siyuan.otf');
  /* src: url('https://img.cagibrand.com/yszsg/font/siyuan.otf'); */
}
body {
  font-family: siyuan, 微软雅黑, 'Heiti SC', 黑体, Arial, sans-serif, serif !important;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
